import { getMonth, format, getYear, differenceInDays } from 'date-fns'
import DateComponent from 'components/generic/date'
import { toDateFormat } from 'lib/dateFormat'

const defaultDataTransformer = (item) => item

const genericDataGrouper = (
  data,
  dataKeyField,
  groupGenerator,
  titleGenerator,
  reverse,
  dataTransformer = defaultDataTransformer,
  includeKeyField
) => {
  if (!data || !Array.isArray(data)) {
    return []
  }

  const groupedData = groupData(
    data,
    dataKeyField,
    groupGenerator,
    titleGenerator,
    dataTransformer,
    includeKeyField
  )

  return reverse
    ? Object.values(groupedData).reverse()
    : Object.values(groupedData)
}

const groupData = (
  data,
  dataKeyField,
  groupGenerator,
  titleGenerator,
  dataTransformer = defaultDataTransformer,
  includeKeyField
) => {
  const groupedData = {}

  data.forEach((item) => {
    const valueForComparison = item[dataKeyField]
    const group = groupGenerator(valueForComparison)

    if (!groupedData[group]) {
      groupedData[group] = {
        key: group,
        title: titleGenerator(valueForComparison),
        items: [],
      }

      if (includeKeyField) {
        groupedData[group][dataKeyField] = valueForComparison
      }
    }

    groupedData[group].items.push(dataTransformer(item))
  })

  return groupedData
}

const groupTextDataByFirstCharacterCaseInsensitiveIgnoringThe = (
  data,
  dataKeyField,
  reverse
) => {
  const specialCharactersGroup = '0-9'

  const groupGenerator = (textDataBeingCompared) => {
    const firstChar = removeTheThe(textDataBeingCompared)
      .charAt(0)
      .toUpperCase()

    return charIsLetter(firstChar) ? firstChar : specialCharactersGroup
  }

  const sortedGroups = genericDataGrouper(
    data,
    dataKeyField,
    groupGenerator,
    groupGenerator,
    reverse
  )

  return sortedGroups
    .map((group) => {
      const reorderedItems = group.items.sort((a, b) =>
        removeTheThe(a[dataKeyField]).localeCompare(
          removeTheThe(b[dataKeyField])
        )
      )

      return {
        ...group,
        items: reorderedItems,
      }
    })
    .sort((a, b) => a.key.localeCompare(b.key))
}

const charIsLetter = (char) => char.toLowerCase() !== char.toUpperCase()

const removeTheThe = (str) => {
  if (typeof str !== 'string' || !str.length) {
    return str
  }

  const firstChar = str.charAt(0).toUpperCase()
  if (firstChar !== 'T') {
    // If it doesn't start with T then it can't start with THE
    return str
  }

  if (str.length < 5) {
    // string isn't long enough to be 'THE X'
    return str
  }

  if (str.toUpperCase().startsWith('THE ')) {
    return str.substring(4)
  }

  return str
}

const getDaysFromEpoch = (date) => {
  const startDate = new Date(0)
  const dateBeingCompared = toDateFormat(date)
  return differenceInDays(dateBeingCompared, startDate)
}

const groupDataByDateInterval = ({
  data,
  dataKeyField,
  interval,
  locale,
  reverse = false,
  dataTransformer = defaultDataTransformer,
  includeKeyField = false,
}) => {
  const groupGenerator = (dateBeingCompared) => {
    switch (interval) {
      case Intervals.Day: {
        // have to get the difference in days between
        // an arbitrary date in the past to keep
        // the day groupings in the correct order
        // e.g. 1st January 2022 > 31st December 2021
        return getDaysFromEpoch(dateBeingCompared)
      }
      case Intervals.Month:
        return getMonth(toDateFormat(dateBeingCompared))
      case Intervals.Year:
        return getYear(toDateFormat(dateBeingCompared))
      case Intervals.MonthAndYear:
        return `${getYear(toDateFormat(dateBeingCompared))}-${format(
          toDateFormat(dateBeingCompared),
          'MM'
        )}`
      case Intervals.YearMonthDate:
        return format(
          toDateFormat(dateBeingCompared),
          DateComponent.Formats.YearMonthDay
        )
      case Intervals.DayAndFullDate:
        return format(
          toDateFormat(dateBeingCompared),
          DateComponent.Formats.StandardWithYear
        )
      default:
        return getDaysFromEpoch(dateBeingCompared)
    }
  }

  const titleGenerator = (dateBeingCompared) => {
    switch (interval) {
      case Intervals.Day:
        return format(
          toDateFormat(dateBeingCompared),
          DateComponent.Formats.Standard,
          {
            locale,
          }
        )
      case Intervals.Month:
        return format(
          toDateFormat(dateBeingCompared),
          DateComponent.Formats.Month,
          {
            locale,
          }
        )
      case Intervals.Year:
        return format(
          toDateFormat(dateBeingCompared),
          DateComponent.Formats.Year,
          {
            locale,
          }
        )
      case Intervals.MonthAndYear:
        return format(
          toDateFormat(dateBeingCompared),
          DateComponent.Formats.MonthAndYear,
          {
            locale,
          }
        )
      case Intervals.YearMonthDate:
        return format(
          toDateFormat(dateBeingCompared),
          DateComponent.Formats.YearMonthDay,
          {
            locale,
          }
        )
      case Intervals.DayAndFullDate:
        return format(
          toDateFormat(dateBeingCompared),
          DateComponent.Formats.StandardWithYear,
          {
            locale,
          }
        )
      default:
        return format(
          toDateFormat(dateBeingCompared),
          DateComponent.Formats.Standard,
          {
            locale,
          }
        )
    }
  }

  return genericDataGrouper(
    data,
    dataKeyField,
    groupGenerator,
    titleGenerator,
    reverse,
    dataTransformer,
    includeKeyField
  )
}

const Intervals = {
  Day: 'day',
  Month: 'month',
  MonthAndYear: 'month-and-year',
  DayAndFullDate: 'day-and-full-date',
  Year: 'year',
  YearMonthDate: 'year-month-date',
}

groupDataByDateInterval.Intervals = Intervals

export {
  genericDataGrouper,
  groupDataByDateInterval,
  removeTheThe,
  groupTextDataByFirstCharacterCaseInsensitiveIgnoringThe,
}
