import { gql } from '@apollo/client'
import { reviewFragment } from './fragments'

const newsFragment = gql`
  fragment newsFragment on IListingItem {
    ... on News {
      id
      index
      live
      headline
      date
      imageUrl
      type
      title
      blurb
      contentUrl
    }
  }
`

const featureFragment = gql`
  fragment featureFragment on IListingItem {
    ... on Feature {
      id
      index
      title
      blurb
      featureDate: date
      featureType: type
      images {
        imageUrl
        type
      }
      imageListingUrl
      contentUrl
      series {
        id
        seriesRef
        title
        contentUrl
      }
      featured
      youtubeId
    }
  }
`

export const GET_CONTRIBUTOR_LISTING = gql`
  query GET_CONTRIBUTOR_LISTING(
    $indices: [IndexType!]
    $aggregations: [ListingAggregationType!]
    $filters: [FilterInput]
    $pageSize: Int
    $page: Int
    $sortField: FilterSortFieldType
    $sortOrder: FilterSortOrderType
  ) {
    listing(
      indices: $indices
      aggregations: $aggregations
      filters: $filters
      pageSize: $pageSize
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      aggregations {
        type
        values {
          value
          name
        }
      }
      data {
        ...reviewFragment
        ...newsFragment
        ...featureFragment
      }
      totalResults
    }
  }
  ${reviewFragment}
  ${newsFragment}
  ${featureFragment}
`

export const GET_DEFAULT_CONTRIBUTOR_LISTING = gql`
  query GET_DEFAULT_CONTRIBUTOR_LISTING(
    $indices: [IndexType!]
    $aggregations: [ListingAggregationType!]
    $filters: [FilterInput]
    $pageSize: Int
    $page: Int
    $sortField: FilterSortFieldType
    $sortOrder: FilterSortOrderType
    $baseFilters: [FilterInput]
  ) {
    listing(
      indices: $indices
      aggregations: []
      filters: $filters
      pageSize: $pageSize
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      data {
        ...reviewFragment
        ...newsFragment
        ...featureFragment
      }
      totalResults
    }
    aggregations: listing(
      indices: $indices
      aggregations: $aggregations
      filters: $baseFilters
      pageSize: 0
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      aggregations {
        type
        values {
          value
          name
        }
      }
    }
  }
  ${reviewFragment}
  ${newsFragment}
  ${featureFragment}
`
