import PropTypes from 'prop-types'
import { Text, variants, Slash } from '@resident-advisor/design-system'
import Heading from 'components/generic/heading'
import testIds from 'enums/testIds'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import { useMenuContext } from 'context/MenuContext'
import { useThemeContext } from 'hooks/useThemeContext'

const GroupedHeader = ({ children }) => {
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)
  const [{ globalNavHeight }] = useMenuContext()
  const theme = useThemeContext()

  return (
    <Heading
      data-testid={testIds.groupedHeaderHeading}
      pb={2}
      pt={{
        enableNewMainNav: enableNewMainNav
          ? `${globalNavHeight + theme.space[1]}px`
          : 2,
      }}
    >
      <Text variant={variants.text.heading.m} color="accent" uppercase>
        <Slash color="accent" />
        {children}
      </Text>
    </Heading>
  )
}

GroupedHeader.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GroupedHeader
