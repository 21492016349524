import PropTypes from 'prop-types'
import { Text, variants } from '@resident-advisor/design-system'

const Lineup = ({ artists = [], ...props }) => {
  if (artists.length === 0) {
    return null
  }

  return (
    <Text variant={variants.text.body} maxLines={2} {...props}>
      {artists.map((a) => a.name).join(', ')}
    </Text>
  )
}

Lineup.propTypes = {
  artists: PropTypes.array,
}

export default Lineup
