import { gql } from '@apollo/client'

const listingNewsFragment = gql`
  fragment listingNewsFragment on News {
    id
    index
    live
    headline
    date
    imageUrl
    type
    title
    blurb
    contentUrl
  }
`

export const GET_NEWS_LISTING = gql`
  query GET_NEWS_LISTING(
    $indices: [IndexType!]
    $aggregations: [ListingAggregationType!]
    $filters: [FilterInput]
    $pageSize: Int
    $page: Int
    $sortField: FilterSortFieldType
    $sortOrder: FilterSortOrderType
  ) {
    listing(
      indices: $indices
      aggregations: $aggregations
      filters: $filters
      pageSize: $pageSize
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      aggregations {
        type
        values {
          value
          name
        }
      }
      data {
        ...listingNewsFragment
      }
      totalResults
    }
  }
  ${listingNewsFragment}
`

export const GET_DEFAULT_NEWS_LISTING = gql`
  query GET_DEFAULT_NEWS_LISTING(
    $indices: [IndexType!]
    $aggregations: [ListingAggregationType!]
    $filters: [FilterInput]
    $pageSize: Int
    $page: Int
    $sortField: FilterSortFieldType
    $sortOrder: FilterSortOrderType
    $baseFilters: [FilterInput]
  ) {
    listing(
      indices: $indices
      aggregations: []
      filters: $filters
      pageSize: $pageSize
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      data {
        ...listingNewsFragment
      }
      totalResults
    }
    aggregations: listing(
      indices: $indices
      aggregations: $aggregations
      filters: $baseFilters
      pageSize: 0
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      aggregations {
        type
        values {
          value
          name
        }
      }
    }
  }
  ${listingNewsFragment}
`
