import { gql } from '@apollo/client'

// we don't want to query `recommended`, since we are fetching only recommended reviews
const recommendedReviewFragment = gql`
  fragment recommendedReviewFragment on IListingItem {
    ... on Review {
      id
      index
      title
      date
      imageUrl
      contentUrl
      blurb
      labels {
        id
        name
        contentUrl
        live
      }
      author {
        id
        name
        imageUrl
        username
        contributor
      }
    }
  }
`

export const GET_RECOMMENDED_REVIEWS_LISTING = gql`
  query GET_RECOMMENDED_REVIEWS_LISTING(
    $indices: [IndexType!]
    $aggregations: [ListingAggregationType!]
    $filters: [FilterInput]
    $pageSize: Int
    $page: Int
    $sortField: FilterSortFieldType
    $sortOrder: FilterSortOrderType
  ) {
    listing(
      indices: $indices
      aggregations: $aggregations
      filters: $filters
      pageSize: $pageSize
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      aggregations {
        type
        values {
          value
          name
        }
      }
      data {
        ...recommendedReviewFragment
      }
      totalResults
    }
  }
  ${recommendedReviewFragment}
`

export const GET_DEFAULT_RECOMMENDED_REVIEWS_LISTING = gql`
  query GET_DEFAULT_RECOMMENDED_REVIEWS_LISTING(
    $indices: [IndexType!]
    $aggregations: [ListingAggregationType!]
    $filters: [FilterInput]
    $pageSize: Int
    $page: Int
    $sortField: FilterSortFieldType
    $sortOrder: FilterSortOrderType
    $baseFilters: [FilterInput]
  ) {
    listing(
      indices: $indices
      aggregations: []
      filters: $filters
      pageSize: $pageSize
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      data {
        ...recommendedReviewFragment
      }
      totalResults
    }
    aggregations: listing(
      indices: $indices
      aggregations: $aggregations
      filters: $baseFilters
      pageSize: 0
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      aggregations {
        type
        values {
          value
          name
        }
      }
    }
  }
  ${recommendedReviewFragment}
`
