import regex from 'lib/regex'

function normalizeNumber(num, intl) {
  const formatOptions = {
    notation: 'compact',
    roundingType: 'fractionDigits',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }

  return String(intl.formatNumber(num, formatOptions)).replace(
    regex.removeTrailingZeroes,
    '$2'
  ) // Remove any trailing zeroes
}

export default normalizeNumber
