import PropTypes from 'prop-types'
import ENTITY_TYPE from 'enums/entity-type'
import NotFound from 'components/error-pages/404'
import ThrowStatusCode from 'components/shared/throw-status-code'
import { STATUS_CODES } from 'components/request-logging'

const Throw404 = (props) => (
  <ThrowStatusCode
    statusCode={STATUS_CODES.NOT_FOUND}
    ErrorComponent={NotFound}
    {...props}
  />
)

Throw404.propTypes = {
  entityType: PropTypes.oneOf(Object.values(ENTITY_TYPE)),
}

export default Throw404
