import PropTypes from 'prop-types'
import { variants, Text } from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import {
  isToday,
  isYesterday,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from 'date-fns'
import events from 'messages/events'
import { toDateFormat } from 'lib/dateFormat'

const TimeSinceAnnounced = ({ datePosted }) => {
  const intl = useIntl()

  return (
    <Text uppercase variant={variants.text.heading.s} color="secondary">
      {getTimeSinceAnnouncedText(datePosted, intl)}
    </Text>
  )
}

TimeSinceAnnounced.propTypes = {
  datePosted: PropTypes.string.isRequired,
}

const getTimeSinceAnnouncedText = (datePosted, intl) => {
  const formatedDatePosted = toDateFormat(datePosted)
  const today = new Date()
  const differenceInMonthsValue = Math.abs(
    differenceInMonths(formatedDatePosted, today)
  )
  const differenceInDaysValue = Math.abs(
    differenceInDays(formatedDatePosted, today)
  )
  const differenceInYearsValue = Math.abs(
    differenceInYears(formatedDatePosted, today)
  )

  if (isToday(formatedDatePosted)) {
    return intl.formatMessage(events.announcedToday)
  }

  if (isYesterday(formatedDatePosted)) {
    return intl.formatMessage(events.announcedYesterday)
  }

  if (differenceInMonthsValue < 1) {
    return intl.formatMessage(events.announcedXDaysAgo, {
      daysSinceAnnounced: differenceInDaysValue,
    })
  }

  if (differenceInMonthsValue >= 1 && differenceInYearsValue < 1) {
    return intl.formatMessage(events.announcedXMonthsAgo, {
      monthsSinceAnnounced: differenceInMonthsValue,
    })
  }

  return intl.formatMessage(events.announcedXYearsAgo, {
    yearsSinceAnnounced: differenceInYearsValue,
  })
}

export default TimeSinceAnnounced
