import { gql } from '@apollo/client'

const eventFragment = gql`
  fragment eventFragment on Event {
    id
    title
    interestedCount
    isSaved
    isInterested
    date
    startTime
    contentUrl
    queueItEnabled
    flyerFront
    newEventForm
    images {
      id
      filename
      alt
      type
      crop
    }
    artists {
      id
      name
    }
    venue {
      id
      name
      contentUrl
      live
      area {
        id
        name
        urlName
        country {
          id
          name
          urlCode
        }
      }
    }
    pick {
      id
      blurb
    }
  }
`

export const GET_EVENTS_LISTING = gql`
  query GET_EVENTS_LISTING(
    $indices: [IndexType!]
    $aggregations: [ListingAggregationType!]
    $filters: [FilterInput]
    $pageSize: Int
    $page: Int
    $sortField: FilterSortFieldType
    $sortOrder: FilterSortOrderType
  ) {
    listing(
      indices: $indices
      aggregations: $aggregations
      filters: $filters
      pageSize: $pageSize
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      aggregations {
        type
        values {
          value
          name
        }
      }
      data {
        ...eventFragment
      }
      totalResults
    }
  }
  ${eventFragment}
`

export const GET_DEFAULT_EVENTS_LISTING = gql`
  query GET_DEFAULT_EVENTS_LISTING(
    $indices: [IndexType!]
    $aggregations: [ListingAggregationType!]
    $filters: [FilterInput]
    $pageSize: Int
    $page: Int
    $sortField: FilterSortFieldType
    $sortOrder: FilterSortOrderType
    $baseFilters: [FilterInput]
  ) {
    listing(
      indices: $indices
      aggregations: []
      filters: $filters
      pageSize: $pageSize
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      data {
        ...eventFragment
      }
      totalResults
    }
    aggregations: listing(
      indices: $indices
      aggregations: $aggregations
      filters: $baseFilters
      pageSize: 0
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      aggregations {
        type
        values {
          value
          name
        }
      }
    }
  }
  ${eventFragment}
`
