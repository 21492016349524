import PropTypes from 'prop-types'
import {
  Alignment,
  Layout,
  Text,
  variants,
} from '@resident-advisor/design-system'
import ENTITY_TYPE from 'enums/entity-type'
import errorMessages from 'messages/errors'
import { useIntl } from 'react-intl'
import dict from 'messages/dict'
import events from 'messages/events'
import magazine from 'messages/magazine'
import music from 'messages/music'
import pro from 'messages/pro'
import Seo from 'components/generic/seo/Seo'

const NotFound = ({ entityType }) => {
  const intl = useIntl()

  const errorMessage = getErrorMessage(entityType, intl)

  return (
    <>
      <Seo title="Page not found ⟋ RA" />
      <Layout>
        <Alignment
          pt={{ s: 6, m: 7 }}
          pb={6}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Text variant={variants.text.label} color="secondary">
            404
          </Text>
          <Text variant={variants.text.heading.xl}>{errorMessage}</Text>
          <Text pt={4} variant={variants.text.heading.l}>
            {intl.formatMessage(errorMessages.sorryThatPageDoesNotExist)}
          </Text>
        </Alignment>
      </Layout>
    </>
  )
}

const entityTypeMessages = {
  [ENTITY_TYPE.Area]: dict.area,
  [ENTITY_TYPE.Artist]: pro.artist,
  [ENTITY_TYPE.Club]: dict.club,
  [ENTITY_TYPE.Event]: events.event,
  [ENTITY_TYPE.Exchange]: music.exchange,
  [ENTITY_TYPE.Feature]: magazine.feature,
  [ENTITY_TYPE.FeatureSeries]: magazine.featureSeries,
  [ENTITY_TYPE.FilmSeries]: magazine.filmSeries,
  [ENTITY_TYPE.Label]: dict.label,
  [ENTITY_TYPE.News]: dict.news,
  [ENTITY_TYPE.Podcast]: dict.podcast,
  [ENTITY_TYPE.Promoter]: dict.promoter,
  [ENTITY_TYPE.Review]: magazine.review,
  [ENTITY_TYPE.Basket]: dict.basket,
  [ENTITY_TYPE.Order]: dict.order,
}

const getErrorMessage = (entityType, intl) => {
  const entityTypeMessage = entityTypeMessages[entityType]

  return entityTypeMessage
    ? intl.formatMessage(errorMessages.notFoundWithEntityType, {
        entityType: intl.formatMessage(entityTypeMessage),
      })
    : intl.formatMessage(errorMessages.notFound)
}

NotFound.propTypes = {
  entityType: PropTypes.oneOf(Object.values(ENTITY_TYPE)),
}

export default NotFound
