import { gql } from '@apollo/client'

const listingReviewFragment = gql`
  fragment listingReviewFragment on Review {
    id
    index
    title
    date
    imageUrl
    contentUrl
    recommended
    blurb
    labels {
      id
      name
      contentUrl
      live
    }
    author {
      id
      name
      imageUrl
      username
      contributor
    }
  }
`

export const GET_REVIEWS_LISTING = gql`
  query GET_REVIEWS_LISTING(
    $indices: [IndexType!]
    $aggregations: [ListingAggregationType!]
    $filters: [FilterInput]
    $pageSize: Int
    $page: Int
    $sortField: FilterSortFieldType
    $sortOrder: FilterSortOrderType
  ) {
    listing(
      indices: $indices
      aggregations: $aggregations
      filters: $filters
      pageSize: $pageSize
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      aggregations {
        type
        values {
          value
          name
        }
      }
      data {
        ...listingReviewFragment
      }
      totalResults
    }
  }
  ${listingReviewFragment}
`

export const GET_DEFAULT_REVIEWS_LISTING = gql`
  query GET_DEFAULT_REVIEWS_LISTING(
    $indices: [IndexType!]
    $aggregations: [ListingAggregationType!]
    $filters: [FilterInput]
    $pageSize: Int
    $page: Int
    $sortField: FilterSortFieldType
    $sortOrder: FilterSortOrderType
    $baseFilters: [FilterInput]
  ) {
    listing(
      indices: $indices
      aggregations: []
      filters: $filters
      pageSize: $pageSize
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      data {
        ...listingReviewFragment
      }
      totalResults
    }
    aggregations: listing(
      indices: $indices
      aggregations: $aggregations
      filters: $baseFilters
      pageSize: 0
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      aggregations {
        type
        values {
          value
          name
        }
      }
    }
  }
  ${listingReviewFragment}
`
