import { gql } from '@apollo/client'

export const reviewFragment = gql`
  fragment reviewFragment on IListingItem {
    ... on Review {
      id
      index
      title
      date
      imageUrl
      contentUrl
      recommended
      blurb
      labels {
        id
        name
        contentUrl
        live
      }
      author {
        id
        name
        imageUrl
        username
        contributor
      }
    }
  }
`
