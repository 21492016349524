import { useRouter } from 'next/router'
import getConfig from 'next/config'
import useQueryWithPagination from 'hooks/useQueryWithPagination'
import useInfiniteScrollPageNumber from 'hooks/useInfiniteScrollPageNumber'
import * as ListingEnums from 'enums/listing'
import {
  GET_DEFAULT_EVENTS_LISTING,
  GET_EVENTS_LISTING,
  GET_DEFAULT_REVIEWS_LISTING,
  GET_REVIEWS_LISTING,
  GET_DEFAULT_CONTRIBUTOR_LISTING,
  GET_CONTRIBUTOR_LISTING,
  GET_DEFAULT_NEWS_LISTING,
  GET_NEWS_LISTING,
} from './queries'

const {
  publicRuntimeConfig: { INFINITE_SCROLL_CONFIG },
} = getConfig()

const useListingQuery = ({
  type,
  baseFilters = [],
  aggregations = [],
  pageSize = parseInt(INFINITE_SCROLL_CONFIG.INFINITE_SCROLL_PAGE_SIZE),
  sortOrder,
  sortField,
  defaultFilters = [],
  queries = undefined,
}) => {
  const { query } = useRouter()

  const parsedQuery = parseQuery(query)

  const isDefault = parsedQuery.length === 0

  const filters = isDefault
    ? [...baseFilters, ...defaultFilters]
    : [...baseFilters, ...parsedQuery]

  const { initialPageNumber, onLoadNextPage, headPaginationAttributes } =
    useInfiniteScrollPageNumber()

  const queriesToUse = queries || Queries[type]

  const queryResult = useQueryWithPagination(
    isDefault ? queriesToUse.default : queriesToUse.modified,
    {
      variables: {
        indices: ListingEnums.INDICES[type],
        pageSize,
        page: initialPageNumber,
        aggregations,
        filters,
        sortOrder,
        sortField,
        baseFilters,
      },
      dataKey: 'listing.data',
      onLoadNextPage,
    }
  )

  const aggregationsData = isDefault
    ? queryResult?.data?.aggregations?.aggregations
    : queryResult?.data?.listing?.aggregations

  return {
    queryResult,
    parsedQuery,
    filters,
    headPaginationAttributes,
    aggregationsData,
  }
}

const Queries = {
  [ListingEnums.TYPE.Event]: {
    default: GET_DEFAULT_EVENTS_LISTING,
    modified: GET_EVENTS_LISTING,
  },
  [ListingEnums.TYPE.Review]: {
    default: GET_DEFAULT_REVIEWS_LISTING,
    modified: GET_REVIEWS_LISTING,
  },
  [ListingEnums.TYPE.Contributor]: {
    default: GET_DEFAULT_CONTRIBUTOR_LISTING,
    modified: GET_CONTRIBUTOR_LISTING,
  },
  [ListingEnums.TYPE.News]: {
    default: GET_DEFAULT_NEWS_LISTING,
    modified: GET_NEWS_LISTING,
  },
}

const parseQuery = (query) => {
  const parsedQuery = []
  Object.entries(query).forEach(([key, value]) => {
    const filter = Object.values(ListingEnums.AGGREGATIONS)?.find(
      (filterValue) => filterValue === key.toUpperCase()
    )
    if (filter) {
      let trimmedFilterValue = null
      if (
        filter === ListingEnums.AGGREGATIONS.Year &&
        typeof value === 'string' &&
        value.endsWith('/')
      ) {
        // Sometimes the year filter is sent with a trailing '/' which breaks the BE
        // this hackaround removes that slash from the parameters.
        // The value must be being set directly in the URL, clean it up here
        trimmedFilterValue = value.slice(0, -1)
      }

      parsedQuery.push({ type: filter, value: trimmedFilterValue || value })
    }
  })
  return parsedQuery
}

export { parseQuery }
export default useListingQuery
